import React, { useEffect, useState } from "react";
import { FaWallet, FaPlus } from "react-icons/fa";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import {
  updateWallet,
  walletBalance,
} from "../../../services/walletServices/walletService";
import { translate } from "../../../utility/helper";
import { useLanguage } from "../../Context/languageContext";
import { useWallet } from "../../Context/walletContext";
import { Oval } from "react-loader-spinner";

const WalletBalance = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const [showModal, setShowModal] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [newBalance, setNewBalance] = useState("");
  const [taxPercentage, setTaxPercentage] = useState(23);
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const { languageData } = useLanguage();
  const { showWalletBalance, balance } = useWallet();

  useEffect(() => {
    if (accessToken) showWalletBalance(accessToken);
  }, []);

  useEffect(() => {
    if (showModal == false) {
      setShowModal(false);
      setNewBalance("");
      setTaxAmount(0);
      setTotalAmount(0);
    }
  }, [showModal]);
  
  const calculateTaxAndTotalAmount = (amount) => {
    const tax = (amount * taxPercentage) / 100;
    const total = parseFloat(amount) + parseFloat(tax);

    setTaxAmount(tax);
    setTotalAmount(total);
  };

  const handleAddBalance = async () => {
    setWalletLoading(true);
    try {
      const response = await updateWallet(newBalance, accessToken);
      if (response.success) {
        if (response.redirect_url_all) {
          window.location.href = response.redirect_url_all;
        } else {
          setShowModal(false);
        }
      } else {
        console.error("API call failed:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setWalletLoading(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div
        onClick={() => setShowModal(true)}
        className="btn btn-outline-primary me-2 px-2 d-flex"
      >
        <div>
          <FaWallet className="me-1" fontSize={18} />
          {balance ? balance : 0} PLN
        </div>
        <FaPlus className="ms-1" fontSize={14} />
      </div>
      {/* Add Balance Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{translate(languageData, "addBalance")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column gap-3">
          <Form.Group as={Row} controlId="balanceAmount">
            <Col>
              <Form.Label>{translate(languageData, "amount")} : </Form.Label>
            </Col>
            <Col sm="8">
              <Form.Control
                type="text"
                placeholder={translate(languageData, "enterAmount")}
                value={newBalance}
                onChange={(e) => {
                  let input = e.target.value;
                  input = input.replace(/[^\d.]/g, "");
                  if (input.includes(".")) {
                    const parts = input.split(".");
                    input = `${parts[0]}.${parts[1].slice(0, 2)}`;
                  }
                  setNewBalance(input);
                  calculateTaxAndTotalAmount(input);
                }}
              />
            </Col>
          </Form.Group>

          {taxAmount > 0 && (
            <>
              <Form.Group as={Row}>
                <Col>
                  <Form.Label>
                    {translate(languageData, "linkTax")} :{" "}
                  </Form.Label>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={taxAmount.toFixed(2)}
                    disabled
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Col>
                  <Form.Label>
                    {translate(languageData, "TotalAmount")} :{" "}
                  </Form.Label>
                </Col>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={totalAmount.toFixed(2)}
                    disabled
                  />
                </Col>
              </Form.Group>
            </>
          )}

          <div className="d-flex gap-2">
            <Button
              variant="primary"
              onClick={handleAddBalance}
              disabled={walletLoading || !newBalance}
            >
              {walletLoading ? (
                <Oval
                  visible={true}
                  height="20"
                  width="40"
                  color="#fff"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                translate(languageData, "addBalance")
              )}
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              {translate(languageData, "close")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WalletBalance;
